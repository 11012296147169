import * as React from "react"

import Signup from "../components/signup"
import Seo from "../components/seo"
import ReduxWrapper from "../redux/reduxWrapper"

const SignupPage = props => {
  return (
    <React.Fragment>
      <Seo title="Signup" />
      <Signup location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<SignupPage {...props} />} />
export default WrappedPage
